/* eslint-disable react/display-name */
/* eslint-disable security/detect-object-injection */
import React from 'react'
import { CustomPageRender } from '../../core/render-connector'
import fetcher from '../../core/fetcher'
import { getBlocksServerData } from '../tools/joker'
import { isPotentiallyLowEndDevice } from '../tools/infos'
import { TemplateProvider } from '../templateProvider'
import ServerPage from '../../core/components/ServerPage'
import { serviceUnavailable, notAuthorized } from '../errorPageRedirects'
import { IPage } from '@bees-web/core/types'

const NfaTemplateRender: CustomPageRender = {
  Render: (props) => <ServerPage {...props} />,
  getServerSideProps: async (context, pageProps) => {
    const { req } = context
    const {
      query,
      locale,
      accountId: selectedPocAccount,
      storeId,
      name: pageName,
      configurations: options = {},
    } = pageProps

    const coreQs = {
      ...query,
      locale,
      ...(selectedPocAccount ? { selectedPocAccount } : {}),
      ...(storeId ? { storeId } : {}),
    }

    const {
      processDeferredOnServer = false,
      cachePage = false,
      noAuthentication = true,
    } = options

    const [language, country] = locale.split('_')

    // TODO: remove this in the future
    if (
      !noAuthentication &&
      !context.req.headers.cookie?.includes('connect.sid')
    ) {
      return notAuthorized(country, language, context?.req?.url)
    }

    let template: IPage = null
    try {
      template = await new TemplateProvider(fetcher(context)).getTemplate(
        pageName,
        coreQs,
        cachePage as boolean
      )
    } catch {
      return notAuthorized(country, language, context?.req?.url)
    }

    if (!template) {
      return serviceUnavailable
    }

    const shouldProcessBlocks =
      typeof processDeferredOnServer === 'string' &&
      processDeferredOnServer.toLowerCase() === 'auto'
        ? isPotentiallyLowEndDevice(req.headers['user-agent'])
        : processDeferredOnServer

    const cachedBlocks = shouldProcessBlocks
      ? await getBlocksServerData(template, context, coreQs, {
          state: false,
          globals: false,
        })
      : {}

    return {
      props: {
        ...pageProps,
        template: template,
        swrCache: cachedBlocks,
      },
    }
  },
}

export default NfaTemplateRender
